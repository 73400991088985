.pageContainer {
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff;
 
}

.scorecardContainer {
  width: 100%;
  max-width: 800px;
  background-color: #edecec;
  /*border: 1px solid #d1d5db;*/
  border-radius: 15px;
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
  padding: 20px;
}

.teamHeader {
  background-color: #4c51bf;
  color: #fff;
  padding: 12px;
  border-radius: 8px 8px 8px 8px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
}

.teamOvers {
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: 10px;
  opacity: 0.8;
}

.scoreTable {
  width: 100%;
  padding: 12px;
  border-collapse: collapse;
}

.tableHeader {
  background-color: #e5e7eb;
  color: #374151;
  text-align: left;
  font-weight: 600;
}

.tableCell {
  padding: 12px;
  border-bottom: 1px solid #d1d5db;
  text-align: left;
  font-size: 0.95rem;
}

.playerDetails {
  display: block;
  font-size: 0.8rem;
  color: #6b7280;
  margin-top: 2px;
}

.extrasLabel {
  font-weight: 600;
  color: #374151;
}

.total {
  margin-top: 10px;
  padding: 12px;
  background-color: #b7b6b6;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalOvers {
  font-size: 0.85rem;
  font-weight: 400;
  opacity: 0.9;
  margin-right: 80px;

}

.didNotBat,
.fallOfWickets {
  margin-top: 10px;
  padding: 12px;
  font-size: 0.9rem;
  color: #374151;
}

@media (max-width: 600px) {
  .scorecardContainer {
    padding: 10px;
  }

  .teamHeader {
    font-size: 1.1rem;
    padding: 10px;
  }

  .teamOvers {
    font-size: 0.8rem;
    
  }

  .tableCell {
    padding: 8px;
    font-size: 0.85rem;
  }

  .total {
    padding: 8px;
    font-size: 0.9rem;
  }

  .totalOvers {
    font-size: 0.75rem;
  }
}

.smallText {
  font-size: 0.75rem; 
}

.gap {
  height: 10px;
}

.headerBar {
  display: flex;
  justify-content: space-around;
  background-color: #b7b6b6; /* Adjust the background color as needed */
  /*  padding: 10px; */
  border-radius: 8px 8px 8px 8px;
  font-weight: bold;
}



.headerItem {
  text-align: left;
  padding: 11px;
  font-weight: bold;
  flex: 1;
}

.tableHeadersContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #bbb;
  font-weight: bold;
  margin-top: 10px;
}


.headerRow {
  background-color: #bbb;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

.teamHeader {
  background-color: #4c51bf;
  color: #fff;
  padding: 12px;
  border-radius: 8px 8px 8px 8px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
}


.headerItems {

  padding: 11px;
}

.boldText {
  font-weight: bold;
  font-size: 1000em;
}

.largeFont {
  font-size: 10.2em;
}

.battingSection {
  background-color: #f0edf1; /* Light blue background for batting section */
  border-radius: 8px 8px 8px 8px;
}

.bowlingSection {
  background-color: #f0edf1; /* Beige background for bowling section */
      border-radius: 8px 8px 8px 8px;
}

.section {
  padding: 10px;
  margin-bottom: 20px;
}

.headerBowling {
  padding: 9px;
}


body {
  background-color: #f8f8f8;
  font-family: sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 2px;
  border: none;
  margin: 8px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
  padding: 10px;
}

.button-white {
  background-color: #332424;
  color: #333;
}

.button-blue {
  background-color: #0a288a;
  color: #6e108b;
}

.clickedButton {
  background-color: #0a288a;
  color: white;
}

.socialShare {
  text-align: center;
  margin-top: 20px;
}

.shareTitle {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.shareButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.shareButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.shareButton .icon {
  font-size: 18px;
}

.facebook {
  background-color: #1877f2;
  color: #fff;
}

.facebook:hover {
  background-color: #135cb3;
}

.twitter {
  background-color: #000000;
  color: #fff;
}

.twitter:hover {
  background-color: #000000;
}

.whatsapp {
  background-color: #25d366;
  color: #fff;
}

.whatsapp:hover {
  background-color: #1da851;
}

