.primary-btn {
  background-color: var(--netural-color-900) !important;
  color: white !important;
  font-family: "DM Sans", sans-serif !important;
  min-width: fit-content !important;
}

.primary-btn:disabled {
  background-color: #f0edf1 !important;
  color: #bdbabd !important;
}

.light-btn {
  background-color: var(--primary-color-400) !important;
  color: var(--fill-black-color) !important;
}

.primary-hollow-btn {
  color: var(--primary-color-600) !important;
  border-color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
  background: white;
  min-width: fit-content !important;
}

.delete-btn {
  background-color: var(--fill-red-color) !important;
  color: var(--primary-color-white) !important;
  border-color: var(--fill-red-color) !important;
  min-width: fit-content !important;
}

.cancel-btn {
  color: var(--error-color) !important;
  border-color: var(--error-color) !important;
  font-size: 14px !important;
}

.small-blue-btn {
  background: var(--card-1-bg) !important;
  color: var(--color3) !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.MuiTextField-root {
  /* box-shadow: var(--main-box-shadow); */
  border-radius: 5px !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiOutlinedInput-input {
  font-family: "DM Sans", sans-serif !important;
}

.MuiTypography-body1 {
  font-family: "DM Sans", sans-serif !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color-500) !important;
  border-radius: 5px !important;
}

.MuiInputBase-input {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px !important;
}

.MuiFormLabel-root {
  color: var(--primary-color-700) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiSelect-icon {
  color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiSelect-select {
  font-family: "DM Sans", sans-serif !important;
}

button.tab {
  background: white !important;
  color: var(--netural-color-900);
  border: 1px solid;
  font-family: "DM Sans", sans-serif;
  text-transform: capitalize;
}

button.active-tab {
  background: var(--netural-color-900) !important;
  color: white;
  font-family: "DM Sans", sans-serif !important;
  text-transform: capitalize;
}

.MuiRadio-root {
  color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MUIDataTableBodyCell-root-781 {
  text-align: "center";
}

.customSmallButton {
  border: none !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  font-family: "DM Sans", "sans-serif" !important;
  padding: 4px 15px !important;
  cursor: pointer;
}

/* CustomOtpInput.css */
.otp-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp-input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 18px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* CustomMUI.css */

.container {
  padding: 16px;
  position: relative;
}

/* TODO: Remove this check flex-between class */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* TODO: Remove this check the new reusable bottom sheet  */
.close-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

/* TODO: Remove this check mt-15 & text-center classes */
.content {
  text-align: center;
  margin-top: 16px;
}

.button-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.card-details {
  width: 65%;
  border-bottom: 1px solid black;
}

.icon-pic-container {
  display: flex;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.icon-pic {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); */
}