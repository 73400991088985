@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap);
:root {
  --primary-color: #254972;
  --neutral-white: #F7F7F7;
  --status-info: #0094F6;
  --text-secondary: #6F6C6F;

  --primary-color-white: #ffffff;
  --primary-color-200: #f8f8f8;
  --primary-color-300: #f2f2f2;
  --primary-color-400: #e4e4e4;
  --primary-color-500: #c4c4c4;
  --primary-color-600: #888888;
  --primary-color-700: #333333;

  --secondary-color-050: #e3ffec;
  --secondary-color-100: #baffcf;
  --secondary-color-200: #84ffae;
  --secondary-color-300: #00ff88;
  --secondary-color-400: #00fb66;
  --secondary-color-500: #00f44e;
  --secondary-color-600: #00e242;
  --secondary-color-700: #00ce33;
  --secondary-color-800: #00bb26;
  --secondary-color-900: #009909;

  --netural-color-050: #e5f1f6;
  --netural-color-100: #bfdceb;
  --netural-color-200: #9ac7df;
  --netural-color-300: #79b1d1;
  --netural-color-400: #63a2c8;
  --netural-color-500: #5093c1;
  --netural-color-600: #4686b5;
  --netural-color-700: #3b75a4;
  --netural-color-800: #326592;
  --netural-color-900: #254972;

  --fill-blue-color: #224a78e5;
  --fill-green-color: #00ff88;
  --fill-red-color: #f95e50;
  --fill-black-color: #1e1e1e;
  --fill-lightyellow-color: #FDFFB6;

  /* extra customize color */
  --color-orange: #FF8050;
  --card-1-bg: #EFF8FF;
  --card-2-bg: #EEF0FF;
  --placeholder-bg: #F0EDF1;
  --delete-btn-bg: #FFADAD;
  --bg1: #EDE8EC;
  --bg2: #84FFAE;
  --bg5: #D9D9D9;
  --bg6: #194055;
  --bg7: #194055ca;
  --bg8: #79B1D1;
  --bg9: #E8EEF8;
  --bg10: #CAFFBF;

  --color1: #89898D;
  --color2: #181518;
  --color3: #6F6C6F;

  --shadow1: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  --shadow3: 0px 1px 10px 0px #19405540;
}
body {
  color: #181518;
  background-color: #fff !important;
  margin: 0;
  font-family: "DM Sans", sans-serif, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--text-secondary) !important;
}

.text-info {
  color: var(--status-info) !important
}

.text-red {
  color: var(--error-color);
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-blue-bg {
  background: var(--primary-color);
  color: white;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-weight: 500 !important;
  border-radius: 5px;
  padding: 1.5%;
  font-size: 16px;
  box-shadow: 0 0 16px 0 rgb(199 209 212 / 86%);
}

.text-white-bg {
  background: white;
  color: var(--primary-color-600);
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 0 8px 0 rgba(119, 168, 184, 0.86);
}

.text-chip {
  background: var(--secondary-color-900);
  color: var(--primary-color-600);
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
}

.chip-small {
  font-size: 12px;
  padding: 2px 7px 2px 7px;
  border-radius: 5px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.me-15 {
  margin-right: 15px !important;
}

.ms-15 {
  margin-left: 15px !important;
}

.mx-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pe-15 {
  padding-right: 15px !important;
}

.ps-15 {
  padding-left: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.g-05 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.d-flex {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem
}

.flex-column {
  flex-direction: column;
}

.flex-center-vertical {
  display: flex;
  flex-direction: column !important;
  align-items: left;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}

.round-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.page-wrapper {
  margin: 15px;
}

.search-players {
  margin-top: 15px;
  margin-bottom: 15px;
  overflow-y: auto;
  height: 30vh;
  text-align: center;
  font-family: "DM Sans", sans-serif;
}

.card {
  margin-bottom: 15px;
  padding: 10px;
  /* box-shadow: var(--main-box-shadow) !important; */
  font-family: "DM Sans", sans-serif;
  width: 100% !important;
}

.card.shadow {
  box-shadow: 0 0 8px 0 rgba(119, 168, 184, 0.86) !important;
}

.scoresheetcard {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: var(--main-box-shadow) !important;
  color: #992c2c;
  width: 355px;
}

.card .card-image {
  width: 40px;
  height: 40px;
  border-radius: 4%;
}

.card .card-image-lg {
  width: 60px;
  height: 60px;
  border-radius: 25%;
}

.bottom-center {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.blur-bg {
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  background-color: rgb(255, 255, 255, 0.4);
}

.fixed {
  position: fixed;
}

:root {
  --main-box-shadow: 0 4px 7px rgb(30, 30, 30, 0.15);
  --main-border: 2px solid;
  --primary-hover-color: #505050;
  --card-margin-S: 10px;
  --card-margin-M: 20px;
  --card-padding: 16px;
  --card-padding-tiny: 10px;
  --card-width: 480px;
  font-family: "DM Sans", sans-serif !important;
}

* {
  box-sizing: border-box;
}

.hide {
  display: none;
}

.app-container {
  height: 93%;
  display: flex;
  background-color: var(--primary-color-white);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.score-ball {
  height: 25px;
  width: 25px;
  background-color: #00a0f0;
  border-radius: 50%;
  font-family: "DM Sans", sans-serif;
}

.series-card {
  background-image: unset !important;
  background-color: #fafafa !important;
}

.label {
  background: linear-gradient(45deg, white 30%, white 90%);
  position: relative;
  border-radius: 3px;
  border: 0;
  color: var(--bg6);
  text-align: center;
  border-radius: 12px;
  width: auto;
  height: 48px;
  padding: 0 0px;
  font-size: 21px;
  box-shadow: 0 0 16px 0 rgb(199 209 212 / 86%);
  font-family: "DM Sans", sans-serif;
  text-transform: capitalize;
}

@media (max-width: 770px) {
  .app-container {
    margin: 0 0;
    align-items: center;
    justify-content: center;
  }

  .image {
    width: 100%;
  }

  .MuiTableCell-head:nth-child(1)>span:nth-child(1) {
    padding-right: 0px !important;
  }

  br.break {
    display: block !important;
  }

  .balls {
    width: 25px !important;
    height: 25px !important;
  }

  .team-wrapper {
    min-width: 5ch !important;
    max-width: 10ch !important;
    padding: 0 4px;
  }

  /* MUIDataTable */
  th {
    font-size: 16px !important;
    text-align: center;
  }

  td {
    font-size: 14px !important;
    text-align: center;
  }

  td.MuiTableCell-body:nth-child(1),
  td.MuiTableCell-head:nth-child(1) {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTableCell-head:nth-child(1)>span:nth-child(1) {
    padding-right: 2px !important;
  }

  .-p-tb {
    padding-top: 60px !important;
    padding-bottom: 30px !important;
  }

  .-p-rl {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Scoresheet */
  div.card-outlined {
    border-color: transparent !important;
    border-radius: 10%;
  }

  div.form-margin {
    margin-bottom: var(--card-margin-S);
  }

  div.card-margin {
    margin-bottom: var(--card-margin-M);
  }
}

@media print {
  body {
    font-size: 10pt;
  }
}

@media screen {
  body {
    font-size: 13px;
  }
}

@media screen,
print {
  body {
    line-height: 1.2;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (resolution: 150dpi) {
  body {
    line-height: 1.4;
  }
}

@media only screen and (max-width: 480px) {
  /* div.button-group-balls { */
  /* width: 100%; */
  /* display: flex; */
  /* margin-bottom: 2px; */
  /* border: 0px; */
  /* } */

  /* div.button-group-balls>button { */
  /* width: 100%; */
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  /* border-radius: 10px !important; */
  /* border-right: 2px solid var(--primary-color-600) !important; */
  /* border-left: 2px solid var(--primary-color-600); */
  /* border-bottom: 2px solid var(--primary-color-600); */
  /* border-top: 2px solid var(--primary-color-600); */
  /* color: var(--primary-color-600); */
  /* font-size: 14px !important; */
  /* text-transform: capitalize; */
  /* } */

  /* div.button-group-ball-type-runs>button { */
  /* font-size: 22px !important; */
  /* font-weight: bold; */
  /* } */

  br.break {
    display: block !important;
  }

  .balls {
    width: 25px !important;
    height: 25px !important;
  }

  .team-wrapper {
    min-width: 5ch !important;
    max-width: 10ch !important;
    padding: 0 4px;
  }

  /* MUIDataTable */
  th {
    font-size: 16px !important;
  }

  td {
    font-size: 14px !important;
  }

  td.MuiTableCell-body:nth-child(1),
  td.MuiTableCell-head:nth-child(1) {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTableCell-head:nth-child(1)>span:nth-child(1) {
    padding-right: 2px !important;
  }

  .-p-tb {
    padding-top: 60px !important;
    padding-bottom: 30px !important;
  }

  .-p-rl {
    padding-left: 1px;
    padding-right: 1px;
  }

  div.card-outlined {
    border-color: transparent !important;
    color: var(--bg6);
  }

  div.form-margin {
    margin-bottom: var(--card-margin-S);
  }

  div.card-margin {
    margin-bottom: var(--card-margin-M);
  }
}

.-p-tb {
  padding-top: 80px;
  padding-bottom: 50px;
}

.score-sum {
  text-align: center;
  padding: 10px;
  border: var(--main-border);
  border-radius: 10px;
  color: var(--primary-color-600);
  width: 100%;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.balls-container {
  padding: 10px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: var(--main-border);
  border-radius: 10px;
  font-size: 14px;
  color: var(--primary-color-600);
}

.balls {
  width: 41px;
  height: 41px;
  border-radius: 100%;
  border: var(--main-border);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 300;
}

.balls-with-runs {
  background: var(--primary-color-600);
  font-family: "DM Sans", sans-serif;
}

.balls-with-runs>span {
  color: #fff;
}

div.card-header {
  border-color: var(--primary-color-600);
  color: var(--primary-color-600);
  border-radius: 20px;
}

.team-wrapper {
  margin-left: 4px;
  margin-right: 4px;
  width: 50%;
  min-width: 16ch;
  max-width: 45ch;
  display: inline-block;
  border: var(--main-border);
  /* text-align: center; */
  border-radius: 20px;
}

/* MUIDataTable */

td.MuiTableCell-body:nth-child(1),
td.MuiTableCell-head:nth-child(1) {
  text-align: left;
}

.MuiTableCell-head:nth-child(1)>span:nth-child(1) {
  padding-right: 75px;
  justify-content: start;
}

div.card-match,
div.card-series {
  box-shadow: var(--main-box-shadow);
  margin-bottom: var(--card-margin-M);
  border-radius: 30px;
}

div.card-match:last-child,
div.card-series:last-child {
  margin-bottom: 0;
}

div.card-match>div.MuiCardContent-root {
  padding: 0px;
}

a.check-score,
a.check-series {
  background: linear-gradient(45deg, white 30%, white 90%);
  position: relative;
  border-radius: 3px;
  border: 0;
  color: var(--primary-color-600);
  text-align: center;
  border-radius: 12px;
  /* width: auto; */
  /* height: 48px; */
  /* padding: 0 0px; */
  font-size: 20px;
  padding: 0 0;
  box-shadow: 0 0 16px 0 rgb(199 209 212 / 86%);
  text-transform: capitalize;
}

/* Scoresheet */

div.card-expand {
  transform-origin: top;
  animation: expand 0.6s 1 ease;
}

div.card-shrink {
  transform-origin: top;
  animation: shrink 0.3s 1 forwards ease;
}

div.card-outlined {
  border-color: var(--primary-color-600);
  margin-bottom: var(--card-margin-M);
}

div.button-group-balls {
  width: 100%;
  display: flex;
  margin-bottom: 2px;
  border: 0px;
}

div.button-group-balls>button {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px !important;
  border: 2px solid var(--primary-color-600) !important;
  color: var(--primary-color-600);
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
}

div.button-group-balls-runout>button {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px !important;
  border: 2px solid var(--primary-color-600) !important;
  color: var(--primary-color-600);
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
}

div.button-group-balls-runout {
  width: 100%;
  display: flex;
  margin-bottom: 2px;
  border: 0px;
}

div.button-group-ball-type-runs>button {
  font-size: 64px;
  font-weight: bold;
}

button.button-end-ball {
  background: linear-gradient(45deg,
      var(--primary-color-600) 30%,
      var(--primary-color-600) 90%);
  color: #fff !important;
}

.end-game {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.end-game>a {
  background: linear-gradient(45deg,
      var(--primary-color-600) 30%,
      var(--primary-color-600) 90%);
  width: 100%;
  margin-top: var(--card-margin-S);
  border-radius: 4px !important;
  color: #fff;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
}

button.button-end-match {
  background: linear-gradient(45deg,
      var(--primary-color-600) 30%,
      var(--primary-color-600) 90%);
  width: 100%;
  margin-top: var(--card-margin-S);
  border-radius: 4px !important;
  color: #fff;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
}

button.button-end-match-disabled {
  /* background: #bbb; */
  border-color: transparent;
  font-family: "DM Sans", sans-serif;
}

button.button-scorescheme {
  box-shadow: 0 0 0 rgb(0, 0, 0, 0);
}

button.active-disabled.Mui-disabled {
  background: linear-gradient(45deg,
      var(--primary-color-600) 30%,
      var(--primary-color-600) 90%);
  /* border: 2px solid var(--primary-color-600) !important; */
}

button.active-disabled>span {
  color: #329fc3 !important;
  color: #1a91c7 !important;
}

button.no-ball-active.Mui-disabled {
  background: #7c1f1f;
  border-color: #7c1f1f !important;
  font-weight: 700;
}

button.no-ball-active>span {
  color: #7c1f1f !important;
  font-weight: 700;
}

button.wide-ball-active.Mui-disabled {
  background: #7c1f1f;
  border-color: #7c1f1f !important;
  font-weight: 700;
}

button.wide-ball-active>span {
  color: #7c1f1f !important;
  font-weight: 700;
}

button.bye-active-disabled>span {
  color: #7c1f1f !important;
  font-weight: 700;
}

button.bye-active-disabled.Mui-disabled {
  background: #7c1f1f;
  border-color: #7c1f1f !important;
  font-weight: 700;
}

button.runout-active-disabled>span {
  color: #7c1f1f !important;
  font-weight: 700;
}

button.runout-active-disabled.Mui-disabled {
  background: #7c1f1f;
  border-color: #7c1f1f !important;
  font-weight: 700;
}

button.label-disabled.Mui-disabled {
  color: #538292e0 !important;
  color: var(--primary-color-600);
  background: var(--secondary-color-900);
  border: 2px solid rgb(0, 0, 0, 0) !important;
  font-family: "DM Sans", sans-serif;
}

div.scorescheme {
  border-radius: 15px;
  border: 2px solid rgb(0, 0, 0, 0) !important;
  /* width: 100%; */
}

/* on error */
div.MuiOutlinedInput-root.Mui-error:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

/* svg arrow on error */
div.MuiOutlinedInput-root.Mui-error>svg.MuiSelect-icon {
  color: #f44336 !important;
}

div.MuiOutlinedInput-root.Mui-error.Mui-focused>svg.MuiSelect-icon {
  color: var(--primary-color-600) !important;
}

div.MuiOutlinedInput-root.Mui-error.Mui-focused:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color-600) !important;
}

div.MuiAlert-root {
  border-radius: 999px;
}

/* Shake on error */

.shake {
  animation: kf_shake 0.4s 1 linear;
  -webkit-animation: kf_shake 0.4s 1 linear;
  -moz-animation: kf_shake 0.4s 1 linear;
  -o-animation: kf_shake 0.4s 1 linear;
}

.reveal {
  animation: reveal 0.2s 1 ease-in;
}

@keyframes expand {
  0% {
    max-height: 0px;
  }

  50% {
    max-height: 290px;
  }

  100% {
    max-height: 550px;
  }
}

@keyframes shrink {
  0% {
    /* margin-bottom: 10px; */
    max-height: 550px;
  }

  100% {
    visibility: hidden;
    margin-bottom: -10px;
    max-height: 0px;
  }
}

@keyframes reveal {
  0% {
    height: 0px;
  }

  30% {
    height: 20px;
  }

  60% {
    height: 60px;
  }

  100% {
    height: auto;
  }
}

@keyframes kf_shake {
  0% {
    transform: translate(30px);
  }

  20% {
    transform: translate(-30px);
  }

  40% {
    transform: translate(15px);
  }

  60% {
    transform: translate(-15px);
  }

  80% {
    transform: translate(8px);
  }

  100% {
    origin-transform: translate(0px);
  }
}

a {
  text-decoration: none;
}

a>h6:hover {
  color: var(--primary-hover-color) !important;
}

/* conflict resolve incoming change */
div.MuiCardActions-root {
  justify-content: center;
}

br.break {
  display: none;
}
.primary-btn {
  background-color: var(--netural-color-900) !important;
  color: white !important;
  font-family: "DM Sans", sans-serif !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.primary-btn:disabled {
  background-color: #f0edf1 !important;
  color: #bdbabd !important;
}

.light-btn {
  background-color: var(--primary-color-400) !important;
  color: var(--fill-black-color) !important;
}

.primary-hollow-btn {
  color: var(--primary-color-600) !important;
  border-color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
  background: white;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.delete-btn {
  background-color: var(--fill-red-color) !important;
  color: var(--primary-color-white) !important;
  border-color: var(--fill-red-color) !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.cancel-btn {
  color: var(--error-color) !important;
  border-color: var(--error-color) !important;
  font-size: 14px !important;
}

.small-blue-btn {
  background: var(--card-1-bg) !important;
  color: var(--color3) !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}

.MuiTextField-root {
  /* box-shadow: var(--main-box-shadow); */
  border-radius: 5px !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiOutlinedInput-input {
  font-family: "DM Sans", sans-serif !important;
}

.MuiTypography-body1 {
  font-family: "DM Sans", sans-serif !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color-500) !important;
  border-radius: 5px !important;
}

.MuiInputBase-input {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px !important;
}

.MuiFormLabel-root {
  color: var(--primary-color-700) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiSelect-icon {
  color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MuiSelect-select {
  font-family: "DM Sans", sans-serif !important;
}

button.tab {
  background: white !important;
  color: var(--netural-color-900);
  border: 1px solid;
  font-family: "DM Sans", sans-serif;
  text-transform: capitalize;
}

button.active-tab {
  background: var(--netural-color-900) !important;
  color: white;
  font-family: "DM Sans", sans-serif !important;
  text-transform: capitalize;
}

.MuiRadio-root {
  color: var(--primary-color-600) !important;
  font-family: "DM Sans", sans-serif !important;
}

.MUIDataTableBodyCell-root-781 {
  text-align: "center";
}

.customSmallButton {
  border: none !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  font-family: "DM Sans", "sans-serif" !important;
  padding: 4px 15px !important;
  cursor: pointer;
}

/* CustomOtpInput.css */
.otp-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp-input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 18px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* CustomMUI.css */

.container {
  padding: 16px;
  position: relative;
}

/* TODO: Remove this check flex-between class */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* TODO: Remove this check the new reusable bottom sheet  */
.close-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

/* TODO: Remove this check mt-15 & text-center classes */
.content {
  text-align: center;
  margin-top: 16px;
}

.button-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.card-details {
  width: 65%;
  border-bottom: 1px solid black;
}

.icon-pic-container {
  display: flex;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.icon-pic {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); */
}
.custom-mobile-input-container {
  display: flex;
  background-color: var(--placeholder-bg);
  flex-direction: column;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 16px;
  margin-bottom: 8px;
}

.custom-mobile-input-label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
}

.custom-phone-input .form-control {
  height: auto !important;
  padding: 0 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  align-items: center;
  width: 100% !important;
  padding-left: 55px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 16px;
  background-color: var(--placeholder-bg) !important;
}

.custom-phone-input .form-control:focus {
  border-color: #e4e4e4;
  background-color: var(--placeholder-bg);
  box-shadow: 0 0 0 2px rgba(127, 127, 128, 0.2);
}

.custom-phone-input .flag-dropdown {
  /* height: 40px; */
  border: none;
  background: none;
  padding: 5px !important;
}

.custom-phone-input .flag-dropdown .selected-flag {
  display: flex;
  align-items: center;
}

.custom-phone-input .flag-dropdown .selected-flag .flag {
  margin-right: 8px;
}
/* src/App.css */
body {
  font-family: 'DM Sans', sans-serif;
}

input,
h1,
p,
textarea,
select {
  font-family: 'DM Sans', sans-serif;
}

/* Apply to Material-UI TextField inputs */
.MuiInputBase-input {
  font-family: 'DM Sans', sans-serif;
}


/* --------------------- bottom sheet --------------------- */
.bottomSheetContainer {
  padding: 0 20px;
}



/* --------------------- for bottom sheet create using drawer --------------------- */
.MuiDrawer-root .MuiDrawer-paperAnchorBottom {
  border-radius: 30px 30px 0 0;
}

/* --------------------- for tabs --------------------- */
.MuiTabs-root .MuiTabs-scrollable .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper {
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.MuiTabs-root .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper {
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.file-upload-container {
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px dashed var(--primary-color-600);
    border-radius: 10px;
    text-align: center;
}

.file-upload-container input {
    display: none;
}

.file-upload-container img {
    width: 100%;
    border-radius: 15px;
}

.intro-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-modal-content {
    position: relative;
    width: 90%;
    height: 90%;
    /* max-width: 600px; */
    background-color: white;
    padding: 3%;
    border-radius: 10px;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.skip {
    /* position: absolute;
    top: 20px;
    right: 20px; */
    /* cursor: pointer;
    color: #6f6c6f;
    font-family: DM Sans;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: end;
    padding: 5px; */
    display: flex;
    justify-content: end;
}

.slide {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
}

.slide-img {
    margin-left: 10%;
    width: 350px;
    /* max-width: 200px; */
    height: 350px;
}

.intro-card {
    margin-top: 20px;
    width: 100%;
    background: linear-gradient(90deg, var(--netural-color-900), #8bb0da);
    color: #fff;
    border-radius: 10px !important;
    padding: 20px !important;
}

.title {
    font-family: Poppins !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    text-align: left;
    margin: 5px 0 !important;
    color: #fff !important;
}

.description {
    font-family: Poppins !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 30px !important;
    text-align: center;
    margin: 15px 0 !important;
    color: #fff !important;
}

.next-button {
    display: flex !important;
    align-self: center;
    margin: 30px auto !important;
    width: 100%;
}

.slick-next,
.slick-prev {
    display: none !important;
}
.SelectedMatchCompo_mainCT__3Umeg {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

.SelectedMatchCompo_container2__3Hlt6 {
    background: #2549720D;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 0px 4px 10px 0px #25497240;
}


.SelectedMatchCompo_container2CP1__3C5dF,
.SelectedMatchCompo_container2CD2P1__19IFk {
    margin: 0;
    background: var(--bg1);
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
}

.SelectedMatchCompo_container2CD2__1P50l {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.SelectedMatchCompo_container2CD2__1P50l:last-child {
    margin-bottom: 0px;
}

.SelectedMatchCompo_container2CP1__3C5dF,
.SelectedMatchCompo_container2CD2P1__19IFk {
    font-size: 16px;
    padding: 3px 10px;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-bottom: 10px;
    color: var(--netural-color-900);
}

.SelectedMatchCompo_container2CD2P1__19IFk {
    box-shadow: none;
    font-size: 14px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    color: #1D1B20;
}

.SelectedMatchCompo_container2CD2CD2__16wXs {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.SelectedMatchCompo_last5WBtns__ZypBQ {
    height: 30px;
    width: 30px;
    background: var(--bg2);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.SelectedMatchCompo_last5WBtnsLoss__2ey2U {
    background: #FF6961;
}

.SelectedMatchCompo_AdContainer__B16Zl {
    height: 100px;
    background: linear-gradient(0deg, rgba(238, 240, 255, 0.29), rgba(238, 240, 255, 0.29));
    border-radius: 20px;
}
.style_shareMainButton__1U_K9 {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    background: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .style_bottomSheetContainer__3FyYh {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .style_header__d5gbv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .style_closeIcon__13Kqt {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .style_shareButtonsContainer__2L52o {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 20px;
  }
  
  .style_shareButton__2sE55 {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .style_facebook__2g5pL {
    background: #1877f2;
  }
  
  .style_twitter__3E3WQ {
    background: #1da1f2;
  }
  
  .style_whatsapp__10woF {
    background: #25d366;
  }
  
.style_pageContainer__1Q3f- {
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff;
 
}

.style_scorecardContainer__2y8EK {
  width: 100%;
  max-width: 800px;
  background-color: #edecec;
  /*border: 1px solid #d1d5db;*/
  border-radius: 15px;
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
  padding: 20px;
}

.style_teamHeader__3syrT {
  background-color: #4c51bf;
  color: #fff;
  padding: 12px;
  border-radius: 8px 8px 8px 8px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
}

.style_teamOvers__kWl2i {
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: 10px;
  opacity: 0.8;
}

.style_scoreTable__iaqXH {
  width: 100%;
  padding: 12px;
  border-collapse: collapse;
}

.style_tableHeader__2LEME {
  background-color: #e5e7eb;
  color: #374151;
  text-align: left;
  font-weight: 600;
}

.style_tableCell__1wNT6 {
  padding: 12px;
  border-bottom: 1px solid #d1d5db;
  text-align: left;
  font-size: 0.95rem;
}

.style_playerDetails__tVawb {
  display: block;
  font-size: 0.8rem;
  color: #6b7280;
  margin-top: 2px;
}

.style_extrasLabel__3Tzyt {
  font-weight: 600;
  color: #374151;
}

.style_total__2G2pp {
  margin-top: 10px;
  padding: 12px;
  background-color: #b7b6b6;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.style_totalOvers__198J5 {
  font-size: 0.85rem;
  font-weight: 400;
  opacity: 0.9;
  margin-right: 80px;

}

.style_didNotBat__1eWFQ,
.style_fallOfWickets__1mGbG {
  margin-top: 10px;
  padding: 12px;
  font-size: 0.9rem;
  color: #374151;
}

@media (max-width: 600px) {
  .style_scorecardContainer__2y8EK {
    padding: 10px;
  }

  .style_teamHeader__3syrT {
    font-size: 1.1rem;
    padding: 10px;
  }

  .style_teamOvers__kWl2i {
    font-size: 0.8rem;
    
  }

  .style_tableCell__1wNT6 {
    padding: 8px;
    font-size: 0.85rem;
  }

  .style_total__2G2pp {
    padding: 8px;
    font-size: 0.9rem;
  }

  .style_totalOvers__198J5 {
    font-size: 0.75rem;
  }
}

.style_smallText__1GM3H {
  font-size: 0.75rem; 
}

.style_gap__fBqy8 {
  height: 10px;
}

.style_headerBar__wWXop {
  display: flex;
  justify-content: space-around;
  background-color: #b7b6b6; /* Adjust the background color as needed */
  /*  padding: 10px; */
  border-radius: 8px 8px 8px 8px;
  font-weight: bold;
}



.style_headerItem__sv02B {
  text-align: left;
  padding: 11px;
  font-weight: bold;
  flex: 1 1;
}

.style_tableHeadersContainer__HnWKc {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #bbb;
  font-weight: bold;
  margin-top: 10px;
}


.style_headerRow__qEPnH {
  background-color: #bbb;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

.style_teamHeader__3syrT {
  background-color: #4c51bf;
  color: #fff;
  padding: 12px;
  border-radius: 8px 8px 8px 8px;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;
}


.style_headerItems__1ekWT {

  padding: 11px;
}

.style_boldText__coTun {
  font-weight: bold;
  font-size: 1000em;
}

.style_largeFont__27ktA {
  font-size: 10.2em;
}

.style_battingSection__132mf {
  background-color: #f0edf1; /* Light blue background for batting section */
  border-radius: 8px 8px 8px 8px;
}

.style_bowlingSection__2Zz3y {
  background-color: #f0edf1; /* Beige background for bowling section */
      border-radius: 8px 8px 8px 8px;
}

.style_section__1RcLz {
  padding: 10px;
  margin-bottom: 20px;
}

.style_headerBowling__TdK_- {
  padding: 9px;
}


body {
  background-color: #f8f8f8;
  font-family: sans-serif;
}

.style_container__A9Rbt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.style_button__1PLvQ {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 2px;
  border: none;
  margin: 8px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
  padding: 10px;
}

.style_button-white__3e9_P {
  background-color: #332424;
  color: #333;
}

.style_button-blue__lTuUR {
  background-color: #0a288a;
  color: #6e108b;
}

.style_clickedButton__1ExIi {
  background-color: #0a288a;
  color: white;
}

.style_socialShare__2iLJU {
  text-align: center;
  margin-top: 20px;
}

.style_shareTitle__3ufN0 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.style_shareButtonsContainer__1ruRP {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.style_shareButton__Cy2XB {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.style_shareButton__Cy2XB .style_icon__vJaIh {
  font-size: 18px;
}

.style_facebook__2tkV7 {
  background-color: #1877f2;
  color: #fff;
}

.style_facebook__2tkV7:hover {
  background-color: #135cb3;
}

.style_twitter__1-5b- {
  background-color: #000000;
  color: #fff;
}

.style_twitter__1-5b-:hover {
  background-color: #000000;
}

.style_whatsapp__2LAx7 {
  background-color: #25d366;
  color: #fff;
}

.style_whatsapp__2LAx7:hover {
  background-color: #1da851;
}



/* batsmanContainer */
.ScoreSheetModal_batsmanContainer__3CUvj {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
}

.ScoreSheetModal_batsManCard__1wBoZ {
    background: var(--bg5);
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
}

.ScoreSheetModal_batsManCard__1wBoZ.ScoreSheetModal_play__3JHLC {
    background: var(--netural-color-900);
}

.ScoreSheetModal_batsManCard__1wBoZ>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ScoreSheetModal_batsManCard__1wBoZ>div>p {
    margin: 0;
    color: #fff;
}

/* ball container */

.ScoreSheetModal_ball__3nzKs {
    height: 30px;
    width: 30px;
    background: #FFADAD;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_run__17mSC {
    background: var(--bg6);
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_run0__2UbBd {
    background: #FFADAD;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_run1__3smIr {
    background: #2E8DFF;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_run2__1k0yP {
    background: #9BF6FF;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_run3__3d9l9 {
    background: #BDB2FF;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_run4__38aEM {
    background: #CAFFBF;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_run6__3VWUS {
    background: #9747FF;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_wicket__2eCCX {
    background: #C80000;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_noball__16sLo {
    background: var(--bg6);
    font-size: 11px;
    font-weight: 400;
    word-break: break-all;
    text-align: center;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_wideball__1SfyT {
    background: var(--bg6);
    font-size: 11px;
    font-weight: 400;
    word-break: break-all;
    text-align: center;
}

.ScoreSheetModal_ball__3nzKs.ScoreSheetModal_freeHit__1DFDU::after {
    content: 'Free Hit';
    position: absolute;
    top: 100%;
    height: 100%;
    width: auto;
    font-size: 10px;
    color: #C80000;
}

.ScoreSheetModal_scrshtcntr3__3zWky>button {
    margin: 0 0 0 10px;
    background: var(--card-1-bg);
    border: none;
    color: var(--color3);
    font-size: 15px;
    padding: 8px 3px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    justify-content: center;
}

/* need run container */

.ScoreSheetModal_scrshtcntr4__2KtPa {
    background: var(--bg5);
    padding: 5px 10px;
}

.ScoreSheetModal_scrshtcntr4__2KtPa p {
    margin: 0;
}

.ScoreSheetModal_firstBattingTeamsConatiner__r_Ct8 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ScoreSheetModal_firstBattingTeamsConatiner__r_Ct8>div:nth-child(1) {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.ScoreSheetModal_firstBattingTeamsConatiner__r_Ct8>div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 5px;
    gap: 5px;
}

.ScoreSheetModal_firstBattingTeamsConatiner__r_Ct8>div:nth-child(1)>p:nth-child(2) {
    font-size: 24px;
}

.ScoreSheetModal_firstBattingTeamsConatiner__r_Ct8>div:nth-child(2)>p {
    background: #fff;
    font-size: 15px;
    padding: 5px;
    line-height: 20px;
    border-radius: 3px;
    color: var(--color3);
}

.ScoreSheetModal_firstBattingTeamsConatiner__r_Ct8>div:nth-child(2)>p>span {
    font-weight: 700;
    color: #000;
}

.ScoreSheetModal_scrshtcntr4__2KtPa>p:last-child {
    margin-left: 30px;
    font-size: 18px;
}
