
/* batsmanContainer */
.batsmanContainer {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.batsManCard {
    background: var(--bg5);
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
}

.batsManCard.play {
    background: var(--netural-color-900);
}

.batsManCard>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.batsManCard>div>p {
    margin: 0;
    color: #fff;
}

/* ball container */

.ball {
    height: 30px;
    width: 30px;
    background: #FFADAD;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
}

.ball.run {
    background: var(--bg6);
}

.ball.run0 {
    background: #FFADAD;
}

.ball.run1 {
    background: #2E8DFF;
}

.ball.run2 {
    background: #9BF6FF;
}

.ball.run3 {
    background: #BDB2FF;
}

.ball.run4 {
    background: #CAFFBF;
}

.ball.run6 {
    background: #9747FF;
}

.ball.wicket {
    background: #C80000;
}

.ball.noball {
    background: var(--bg6);
    font-size: 11px;
    font-weight: 400;
    word-break: break-all;
    text-align: center;
}

.ball.wideball {
    background: var(--bg6);
    font-size: 11px;
    font-weight: 400;
    word-break: break-all;
    text-align: center;
}

.ball.freeHit::after {
    content: 'Free Hit';
    position: absolute;
    top: 100%;
    height: 100%;
    width: auto;
    font-size: 10px;
    color: #C80000;
}

.scrshtcntr3>button {
    margin: 0 0 0 10px;
    background: var(--card-1-bg);
    border: none;
    color: var(--color3);
    font-size: 15px;
    padding: 8px 3px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    justify-content: center;
}

/* need run container */

.scrshtcntr4 {
    background: var(--bg5);
    padding: 5px 10px;
}

.scrshtcntr4 p {
    margin: 0;
}

.firstBattingTeamsConatiner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.firstBattingTeamsConatiner>div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 5px;
}

.firstBattingTeamsConatiner>div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.firstBattingTeamsConatiner>div:nth-child(1)>p:nth-child(2) {
    font-size: 24px;
}

.firstBattingTeamsConatiner>div:nth-child(2)>p {
    background: #fff;
    font-size: 15px;
    padding: 5px;
    line-height: 20px;
    border-radius: 3px;
    color: var(--color3);
}

.firstBattingTeamsConatiner>div:nth-child(2)>p>span {
    font-weight: 700;
    color: #000;
}

.scrshtcntr4>p:last-child {
    margin-left: 30px;
    font-size: 18px;
}