@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

/* src/App.css */
body {
  font-family: 'DM Sans', sans-serif;
}

input,
h1,
p,
textarea,
select {
  font-family: 'DM Sans', sans-serif;
}

/* Apply to Material-UI TextField inputs */
.MuiInputBase-input {
  font-family: 'DM Sans', sans-serif;
}


/* --------------------- bottom sheet --------------------- */
.bottomSheetContainer {
  padding: 0 20px;
}



/* --------------------- for bottom sheet create using drawer --------------------- */
.MuiDrawer-root .MuiDrawer-paperAnchorBottom {
  border-radius: 30px 30px 0 0;
}

/* --------------------- for tabs --------------------- */
.MuiTabs-root .MuiTabs-scrollable .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper {
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.MuiTabs-root .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper {
  text-transform: capitalize;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}